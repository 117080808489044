<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - Usage By Protocol</h3>
			<div class="d-flex align-items-center justify-content-between">
				<div>
					<a @click="downloadCSV" style="cursor: pointer">
						<span class="svg-icon svg-icon-lg">
							<inline-svg src="/media/svg/icons/Files/Download.svg" />
						</span>
						Download CSV
					</a>
				</div>
			</div>
		</div>
		<div class="card-body pt-2">
			<ITCSpinner :loaded="loaded">
				<div class="row">
					<div class="col-md-6">
						<h2>RX</h2>
						<apexchart type="bar" :height="height" :options="rxoptions" :series="seriesrx" />
					</div>
					<div class="col-md-6">
						<h2>TX</h2>
						<apexchart type="bar" :height="height" :options="txoptions" :series="seriestx" />
					</div>
				</div>
				<div class="row">
					<div class="col-md-4"></div>
					<div class="col-md-4">
						<table class="table table-striped table-hover table-sm">
							<thead>
								<tr>
									<td>Network</td>
									<td>RX</td>
									<td>TX</td>
								</tr>
							</thead>
							<tbody>
								<tr v-if="total_data.length > 1">
									<td>
										<u> All Networks </u>
									</td>
									<td>
										<u>
											{{ all_rx_total }}
										</u>
									</td>
									<td>
										<u>
											{{ all_tx_total }}
										</u>
									</td>
								</tr>
								<tr v-for="(data, x) in total_data" v-bind:key="x">
									<td>
										{{ data.NetworkName }}
									</td>
									<td>
										<i :style="{ color: rxoptions.colors[x] }">█</i>
										{{ data.rx_total }}
									</td>
									<td>
										<i :style="{ color: txoptions.colors[x] }">█</i>
										{{ data.tx_total }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="col-md-4"></div>
				</div>
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
import { KMG } from '@/helpers';
import swMixin from '@/core/services/mixins/serviceworker.mixin';

export default {
	name: 'IdirectUsageByProtocol',
	mixins: [swMixin],
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	props: {
		name: { Type: String, required: true },
		DID: {
			type: Number,
			required: true,
		},
		properties: { type: Object, required: false },
		updated: {
			type: Number,
		},
	},
	data() {
		return {
			buttons: [
				{
					text: '1 Week',
					value: 7,
				},
				{ text: '30 Days', value: 30 },
				{ text: '60 Days', value: 60 },
			],
			csv_networks: [],
			daysSelected: 30,
			loaded: false,
			data: [],
			height: 200,
			txoptions: null,
			rxoptions: null,
			seriestx: [],
			seriesrx: [],
			all_rx_total: 0,
			all_tx_total: 0,
			total_data: 0,
		};
	},
	computed: {
		intstr() {
			if (this.properties?.interfacestotal) {
				let str = '';
				for (let intname in this.properties.interfacestotal) {
					if (this.properties.interfacestotal[intname]) {
						if (str != '') {
							str += ',';
						}
						str += intname;
					}
				}
				if (str != '') {
					return '&interfacestotal=' + str;
				}
			}
			return '';
		},
	},
	methods: {
		processData(dd) {
			this.csv_networks = [];

			this.all_rx_total = 0;
			this.all_tx_total = 0;
			this.total_data = [];
			var rxseries = [];
			var txseries = [];
			dd.forEach(network => {
				var tx_total =
					network.rx_tcp + network.rx_udp + network.rx_icmp + network.rx_igmp + network.rx_other;
				var rx_total =
					network.tx_tcp + network.tx_udp + network.tx_icmp + network.tx_igmp + network.tx_other;
				if (isNaN(rx_total)) {
					rx_total = 0;
				}
				if (isNaN(tx_total)) {
					tx_total = 0;
				}
				if (isNaN(network.sumrx)) {
					network.sumrx = 0;
				}
				if (isNaN(network.sumtx)) {
					network.sumtx = 0;
				}
				this.all_rx_total += rx_total; //Math.round(network.sumrx);
				this.all_tx_total += tx_total;
				if (rx_total !== 0 || tx_total !== 0) {
					this.total_data.push({
						NetworkName: network.NetworkName,
						rx_total: KMG(rx_total),
						rx_index: rxseries.length + 1,
						tx_total: KMG(tx_total),
						tx_index: txseries.length,
					});

					txseries.push({
						name: 'TX - ' + network.NetworkName,
						data: [
							network.rx_http,
							network.rx_tcp - network.rx_http,
							network.rx_udp,
							network.rx_icmp,
							network.rx_igmp,
							network.rx_other,
						],
					});
					rxseries.push({
						name: 'RX - ' + network.NetworkName,
						data: [
							network.tx_http,
							network.tx_tcp - network.tx_http,
							network.tx_udp,
							network.tx_icmp,
							network.tx_igmp,
							network.tx_other,
						],
					});
				}
				this.csv_networks.push(network);
			});

			this.seriesrx = rxseries;
			this.seriestx = txseries;
			this.rxoptions = {
				chart: {
					type: 'bar',
					height: 350,
					stacked: true,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					bar: {
						borderRadius: 8,
						horizontal: true,
					},
				},
				stroke: {
					width: 1,
					colors: ['#fff'],
				},
				colors: [
					'#7cb5ec',
					'#434348',
					'#90ed7d',
					'#f7a35c',
					'#8085e9',
					'#f15c80',
					'#e4d354',
					'#2b908f',
					'#f45b5b',
					'#91e8e1',
					'#7cb5ec',
					'#434348',
					'#90ed7d',
					'#f7a35c',
					'#8085e9',
					'#f15c80',
					'#e4d354',
					'#2b908f',
					'#f45b5b',
				],
				dataLabels: {
					enabled: false,
				},
				xaxis: {
					categories: ['TCP HTTP', 'TCP Other', 'UDP', 'ICMP', 'IGMP', 'Other'],
					labels: {
						formatter: function(val) {
							return KMG(val) + 'B';
						},
					},
				},
				yaxis: {
					title: {
						text: undefined,
					},
					max:
						this.all_rx_total > this.all_tx_total
							? this.all_rx_total + this.all_rx_total * 0.1
							: this.all_tx_total + this.all_tx_total * 0.1,
				},
				tooltip: {
					y: {
						formatter: function(val) {
							return KMG(val) + 'B';
						},
					},
				},
				fill: {
					opacity: 1,
				},
				legend: {
					position: 'top',
					horizontalAlign: 'left',
					offsetX: 40,
				},
			};
			this.txoptions = Object.assign({}, this.rxoptions);
			this.txoptions.colors = [
				'#91e8e1',
				'#f7a35c',
				'#8085e9',
				'#f15c80',
				'#e4d354',
				'#2b908f',
				'#f45b5b',
				'#91e8e1',
				'#7cb5ec',
				'#434348',
				'#90ed7d',
				'#f7a35c',
				'#8085e9',
				'#f15c80',
				'#e4d354',
				'#2b908f',
				'#f45b5b',
				'#91e8e1',
				'#7cb5ec',
				'#434348',
				'#90ed7d',
			];

			this.all_rx_total = KMG(this.all_rx_total);
			this.all_tx_total = KMG(this.all_tx_total);
		},
		downloadCSV() {
			let csv = 'data:text/csv;charset=utf-8,';
			csv +=
				'Network,rx_http,rx_icmp,rx_igmp,rx_other,rx_tcp,rx_udp,tx_http,tx_icmp,tx_igmp,tx_other,tx_tcp,tx_udp\n';
			this.csv_networks.forEach(network => {
				csv += network.NetworkName + ',';
				csv += network.rx_http + ',';
				csv += network.rx_icmp + ',';
				csv += network.rx_igmp + ',';
				csv += network.rx_other + ',';
				csv += network.rx_tcp + ',';
				csv += network.rx_udp + ',';
				csv += network.tx_http + ',';
				csv += network.tx_icmp + ',';
				csv += network.tx_igmp + ',';
				csv += network.tx_other + ',';
				csv += network.tx_tcp + ',';
				csv += network.tx_udp + ',';
				csv += '\n';
			});
			const data = encodeURI(csv);
			const link = document.createElement('a');
			link.setAttribute('href', data);
			link.setAttribute('download', this.name + ' Daily Usage.csv');
			link.click();
		},
		load() {
			this.loaded = false;
			this.$http.get(`idirect/traffic/protocol/${this.DID}`, this.intstr).then(resp => {
				this.processData(resp.data.data);
				this.csv = resp.data.csv;
				this.loaded = true;
			});
		},
	},
	watch: {
		DID() {
			console.log('DID UPDATED');
		},
		updated() {
			this.load();
		},
	},
	created() {
		this.load();
	},
};
</script>

<style>
.fixed-table-head {
	overflow-y: scroll;
}
table.fixedTableHead thead th {
	position: sticky;
	top: 0;
	background: #fff;
	box-shadow: 0 4px 10px -2px #eef1f5;
}
</style>
